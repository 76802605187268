import React from "react";
import moment from "moment-timezone";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { SEO, Theme, ScheduleCallout } from "src/components";

import Intro from "./_components/_intro";
import Main from "./_components/_main";
import ArtistNavigation from "./_components/_artistNavigation";
import Related from "./_components/_related";

const FestivalArtistPage = ({ data, setInvert, setInvertLogo }) => {
  let artist = {};
  let schedule = [];
  let sessionSchedule = [];
  let festival = {};
  let artists = [];
  let nextArtist = undefined;
  let previousArtist = undefined;
  if (data.festival) {
    if (data.festival.dataString) {
      festival = JSON.parse(data.festival.dataString);
      festival.uid = data.festival.uid;
    }
  }
  if (data.artists) {
    let sortedArtists = data.artists.edges.sort((a, b) => {
      if (
        (a.node.uid && a.node.uid.replace("the-", "")) <
        (b.node.uid && b.node.uid.replace("the-", ""))
      )
        return -1;
      if (
        (a.node.uid && a.node.uid.replace("the-", "")) >
        (b.node.uid && b.node.uid.replace("the-", ""))
      )
        return 1;
      return 0;
    });

    sortedArtists = sortedArtists.filter((artist) => {
      let found = false;
      festival.schedule.forEach((scheduleItem) => {
        if (
          scheduleItem.primary.performer &&
          scheduleItem.primary.performer.id === artist.node.prismicId
        ) {
          found = true;
        }
      });
      if (found) {
        return true;
      } else {
        return false;
      }
    });
    sortedArtists.forEach((artist, index) => {
      if (artist.node.uid === data.artist.uid) {
        if (index > 0) {
          previousArtist = {
            ...JSON.parse(sortedArtists[index - 1].node.dataString),
            uid: sortedArtists[index - 1].node.uid,
            id: sortedArtists[index - 1].node.prismicId,
          };
        }
        if (index < sortedArtists.length - 1) {
          nextArtist = {
            ...JSON.parse(sortedArtists[index + 1].node.dataString),
            uid: sortedArtists[index + 1].node.uid,
            id: sortedArtists[index + 1].node.prismicId,
          };
        }
      }
      artists.push({
        ...JSON.parse(artist.node.dataString),
        uid: artist.node.uid,
        id: artist.node.prismicId,
      });
    });
  }
  if (data.artist) {
    if (data.artist.dataString) {
      artist = JSON.parse(data.artist.dataString);
      // Find bio author
      data.authors.edges.forEach((author) => {
        if (artist.bio_contributer) {
          if (author.node.prismicId === artist.bio_contributer.id) {
            artist.bioAuthor = JSON.parse(author.node.dataString);
          }
        }
      });
      let relatedArtists = [];
      // Populate artist performances
      festival.schedule.forEach((scheduleItem) => {
        if (
          scheduleItem.primary.performer &&
          scheduleItem.primary.performer.id === data.artist.prismicId &&
          scheduleItem.primary.start_time
        ) {
          data.stages.edges.forEach((stageInfo) => {
            if (
              scheduleItem.primary.stage &&
              stageInfo.node.prismicId === scheduleItem.primary.stage.id
            ) {
              scheduleItem.primary.stageInfo = JSON.parse(
                stageInfo.node.dataString
              );
            }
          });
          schedule.push(scheduleItem);
        }
        if (scheduleItem.items) {
          scheduleItem.items.forEach((item) => {
            if (item.artist) {
              if (item.artist.id === data.artist.prismicId) {
                data.stages.edges.forEach((stageInfo) => {
                  if (
                    scheduleItem.primary.stage &&
                    stageInfo.node.prismicId === scheduleItem.primary.stage.id
                  ) {
                    scheduleItem.primary.stageInfo = JSON.parse(
                      stageInfo.node.dataString
                    );
                  }
                });
                sessionSchedule.push(scheduleItem);
              }
            }
            if (scheduleItem.items && scheduleItem.items[0].artist) {
              scheduleItem.performerData = [];
              scheduleItem.items.forEach((itemArtist) => {
                artists.forEach((artist) => {
                  if (artist.id === itemArtist.artist.id) {
                    scheduleItem.performerData.push(artist);
                  }
                });
              });
            }
          });
        }
      });
      schedule = schedule.sort((a, b) => {
        if (
          (a.primary.start_time && moment(a.primary.start_time).unix()) <
          (b.primary.start_time && moment(b.primary.start_time).unix())
        )
          return -1;
        if (
          (a.primary.start_time && moment(a.primary.start_time).unix()) >
          (b.primary.start_time && moment(b.primary.start_time).unix())
        )
          return 1;
        return 0;
      });
      sessionSchedule = sessionSchedule.sort((a, b) => {
        if (
          (a.primary.start_time && moment(a.primary.start_time).unix()) <
          (b.primary.start_time && moment(b.primary.start_time).unix())
        )
          return -1;
        if (
          (a.primary.start_time && moment(a.primary.start_time).unix()) >
          (b.primary.start_time && moment(b.primary.start_time).unix())
        )
          return 1;
        return 0;
      });
      // Populate related
      if (artist.related_artists && festival.schedule) {
        data.artists.edges.forEach((artistInfo) => {
          artist.related_artists.forEach((relatedArtist) => {
            if (relatedArtist.artist.id === artistInfo.node.prismicId) {
              relatedArtists.push({
                ...JSON.parse(artistInfo.node.dataString),
                uid: artistInfo.node.uid,
              });
            }
          });
        });
      }
      artist.relatedArtists = relatedArtists;
    }
  }

  return (
    <>
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          <SEO
            title={
              (artist.artist_name && RichText.asText(artist.artist_name)) +
              " @ " +
              (festival.title && RichText.asText(festival.title))
            }
            description={artist.bio && RichText.asText(artist.bio)}
            image={artist.image && artist.image.url && artist.image.url}
            imageAlt={artist.image && artist.image.alt && artist.image.alt}
          />
          <Intro
            festivalTitle={festival.title && RichText.asText(festival.title)}
            title={artist.artist_name && RichText.asText(artist.artist_name)}
            location={artist.location}
            image={artist.image && artist.image}
            video={
              artist.youtube_video &&
              artist.youtube_video.url &&
              artist.youtube_video.url.split("/")[
                artist.youtube_video.url.split("/").length - 1
              ]
            }
            illustrations={festival.illlustrations}
            listenOptions={artist.listen}
            socialNetworks={artist.social_links}
            setInvert={setInvert}
            setInvertLogo={setInvertLogo}
            theme={festival.theme}
          />
        </>
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.2, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          <Main
            festivalId={festival.uid}
            artist={artist}
            ticketLink={
              festival.ticket_link &&
              festival.ticket_link.url &&
              festival.ticket_link
            }
            schedule={schedule}
            sessionSchedule={sessionSchedule}
            bio={
              artist.bio &&
              RichText.render(artist.bio, linkResolver, serializer)
            }
            bioAuthor={artist.bioAuthor}
            incompleteDescription={festival.schedule_incomplete_prompt}
            listenOptions={artist.listen}
            darkMode={festival.theme === "Dark theme" ? true : false}
          />
          {/* TEMP CHANGE FOR SUMMER SERENADES */}
          {/* {festival.schedule_complete === "Yes" && (
            <ScheduleCallout festival={festival.uid} />
          )} */}
          <ArtistNavigation
            festival={festival.uid}
            nextArtist={nextArtist}
            previousArtist={previousArtist}
          />
          {artist.relatedArtists.length !== 0 && (
            <Related
              festivalUrl={festival.uid}
              relatedArtists={artist.relatedArtists}
            />
          )}
        </>
      </ThemeProvider>
    </>
  );
};

export default FestivalArtistPage;

export const query = graphql`
  query ($festivalId: String!, $uid: String!) {
    festival: prismicFestival(id: { eq: $festivalId }) {
      prismicId
      uid
      dataString
    }
    artist: prismicArtist(uid: { eq: $uid }) {
      uid
      prismicId
      dataString
    }
    artists: allPrismicArtist {
      edges {
        node {
          uid
          prismicId
          dataString
        }
      }
    }
    stages: allPrismicStage {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
    authors: allPrismicStaffMember {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
  }
`;
