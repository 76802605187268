import React from "react";

import { Box, FlexGrid, SectionWrapper } from "src/components";

import About from "./_about";
import Performances from "./_performances";
import Sidebar from "./_sidebar";

const Main = ({
  festivalId,
  ticketLink,
  schedule,
  bio,
  bioAuthor,
  incompleteDescription,
  sessionSchedule,
  listenOptions,
  artist,
  darkMode
}) => (
  <SectionWrapper py={[7, 8]} bg="bg.default">
    <FlexGrid gutterX={[5, 5, 7, 8]} gutterY={[5, 7]}>
      <Box as="aside" width={[1, 1 / 3]}>
        <Sidebar
          festivalId={festivalId}
          ticketLink={ticketLink}
          listenOptions={listenOptions}
        />
      </Box>
      <Box as="section" width={[1, 2 / 3]}>
        <About bio={bio} bioAuthor={bioAuthor} />
        <Performances
          festivalId={festivalId}
          schedule={schedule}
          sessionSchedule={sessionSchedule}
          incompleteDescription={incompleteDescription}
          artist={artist}
          darkMode={darkMode}
        />
      </Box>
    </FlexGrid>
  </SectionWrapper>
);

export default Main;
