import React, { useState } from "react";
import { mdiPlay } from "@mdi/js";

import {
  Box,
  H1,
  H6,
  Icon,
  IlloWrapper,
  PrismicImage,
  SplitHeader,
  Text
} from "src/components";

import SocialLinks from "./_socialLinks";

const PlayBox = () => (
  <div
    css={`
      position: absolute;
      bottom: ${props => props.theme.space[3] + "px"};
      left: ${props => props.theme.space[3] + "px"};
      z-index: 10;
      ${props => props.theme.mediaQueries.large} {
        top: ${props => props.theme.space[6] + "px"};
        right: ${props => "-" + props.theme.space[5] + "px"};
        bottom: initial;
        left: initial;
      }
    `}
  >
    <div
      css={`
        padding: ${props => props.theme.space[3] + "px"};
        display: inline-block;
        border-radius: 9999px;
        background-color: #fff;
      `}
    >
      <Icon
        symbol={mdiPlay}
        css={`
          width: ${props => props.theme.space[5] + "px"} !important;
          height: ${props => props.theme.space[5] + "px"} !important;
          fill: #1b1a19;
          vertical-align: top;
        `}
      />
    </div>
  </div>
);

function Intro({
  festivalTitle,
  title,
  location,
  image,
  illustrations,
  video,
  socialNetworks,
  listenOptions,
  setInvert,
  setInvertLogo,
  theme
}) {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <SplitHeader
        left={
          <>
            {image && (
              <div>
                <Box
                  position="relative"
                  bg="bg.reverse"
                  style={{ cursor: video && "pointer" }}
                >
                  <Box
                    css={`
                      position: relative;
                    `}
                    style={{
                      transition: "opacity 300ms ease-in-out",
                      opacity: showVideo ? 0 : 1
                    }}
                    onClick={() => {
                      if (video) {
                        setShowVideo(!showVideo);
                      }
                    }}
                  >
                    <IlloWrapper
                      nw={2}
                      sw={4}
                      se={1}
                      illustrations={illustrations}
                      size={4}
                    >
                      <PrismicImage image={image} alt={title && title} />
                    </IlloWrapper>
                    {video && <PlayBox />}
                  </Box>
                  {showVideo && (
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                    >
                      <iframe
                        title="youtubePlayer"
                        id="ytplayer"
                        type="text/html"
                        width="100%"
                        height="100%"
                        src={"https://www.youtube.com/embed/" + video}
                        frameborder="0"
                      />
                    </Box>
                  )}
                </Box>
              </div>
            )}
          </>
        }
        right={
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
              ${props => props.theme.mediaQueries.large} {
                align-items: flex-start;
              }
            `}
          >
            {festivalTitle && (
              <H6
                children={festivalTitle}
                textAlign={["center", null, null, "initial"]}
                color="text.reverse"
              />
            )}
            {title && (
              <H1
                children={title}
                mt={4}
                textAlign={["center", null, null, "initial"]}
                color="text.reverse"
              />
            )}
            {location && (
              <Text
                children={`from ` + location}
                mt={3}
                fontSize={[0, 1]}
                textAlign={["center", null, null, "initial"]}
                color="text.reverse"
              />
            )}
            <div
              css={`
                margin-top: auto;
                padding-top: ${props => props.theme.space[4] + "px"};
              `}
            >
              <SocialLinks
                socialNetworks={socialNetworks}
                listenOptions={listenOptions}
              />
            </div>
          </div>
        }
        color="brand.primary"
        invert={true}
        setInvertLogo={setInvertLogo}
        setInvert={setInvert}
        invertLogoOverride={theme === "Dark theme" ? true : false}
      />
    </>
  );
}

export default Intro;
