import React from "react";
import { Link } from "gatsby";
import moment from "moment-timezone";
import { RichText } from "prismic-reactjs";

import { Box, FlexGrid, H3, H6, Text, AddToSchedule } from "src/components";

const Concert = ({ festivalId, concert, artist, darkMode }) => (
  <Box width={1}>
    {concert.primary.stageInfo && (
      <div
        css={`
          padding-left: ${(props) => props.theme.space[4] + "px"};
          border-left: 1px solid ${(props) => props.theme.colors.bg.alt};
        `}
      >
        {concert.primary.start_time && (
          <Text
            children={moment(concert.primary.start_time)
              .tz("America/Edmonton")
              .format(`dddd MMMM D, YYYY`)}
            fontSize={3}
          />
        )}
        {concert.primary.start_time && concert.primary.end_time && (
          <Text
            children={
              moment(concert.primary.start_time)
                .tz("America/Edmonton")
                .format("h:mm A") +
              " – " +
              moment(concert.primary.end_time)
                .tz("America/Edmonton")
                .format("h:mm A") +
              " · " +
              (concert.primary.stageInfo.stage_name &&
                RichText.asText(concert.primary.stageInfo.stage_name)) +
              (concert.primary.stageInfo.stage_subname
                ? ` @ ` + concert.primary.stageInfo.stage_subname
                : concert.primary.stageInfo.stage_number === 0
                ? ""
                : ` Stage ` + concert.primary.stageInfo.stage_number)
            }
            mb={concert.performerData && 2}
            color="text.body"
          />
        )}
        {concert.performerData && typeof concert.performerData === Array && (
          <>
            {concert.performerData.map((artist, index) => (
              <Link
                key={artist + index}
                to={"/" + festivalId + "/artist/" + artist.uid}
              >
                <span>
                  {index > 0 && ", "}
                  {artist.artist_name &&
                    artist.artist_name[0] &&
                    artist.artist_name[0].text}
                </span>
              </Link>
            ))}
          </>
        )}
        {/* TEMP CHANGE FOR SUMMER SERENADES */}
        {/* <Box mt={2}>
          <AddToSchedule
            item={{ ...concert, performerData: artist }}
            view={"/" + festivalId + "/schedule/?mySchedule=true"}
            darkMode={darkMode}
          />
        </Box> */}
      </div>
    )}
  </Box>
);

const Performances = ({
  festivalId,
  schedule,
  sessionSchedule,
  incompleteDescription,
  artist,
  darkMode,
}) => (
  <div>
    {schedule.length === 0 && <Text children={incompleteDescription} />}
    <FlexGrid gutterY={[7, 8]}>
      {schedule.length !== 0 && (
        <Box width={1}>
          <H3 children={`Concerts`} mb={5} />
          <FlexGrid gutterY={[5]}>
            {schedule.map((scheduleItem, index) => (
              <Concert
                festivalId={festivalId}
                concert={scheduleItem}
                artist={artist}
                key={"scheduleItem" + index}
                darkMode={darkMode}
              />
            ))}
          </FlexGrid>
        </Box>
      )}
      {sessionSchedule.length !== 0 && (
        <Box width={1}>
          <H3 children={`Sessions`} />
          <H6
            children={`A collaboration with others`}
            mt={2}
            mb={5}
            color="text.alt"
          />
          <FlexGrid gutterY={[5]}>
            {sessionSchedule.map((scheduleItem, index) => (
              <Concert
                festivalId={festivalId}
                concert={scheduleItem}
                key={"scheduleItem" + index}
                darkMode={darkMode}
              />
            ))}
          </FlexGrid>
        </Box>
      )}
    </FlexGrid>
  </div>
);

export default Performances;
