import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";

import {
  Box,
  Button,
  FlexGrid,
  H2,
  PrismicImage,
  SectionWrapper,
  Text
} from "src/components";

const Related = ({ festivalUrl, relatedArtists }) => (
  <SectionWrapper id="related" py={[8, 9, 10]} bg="bg.reverse">
    <FlexGrid gutterX={[3, 6]} gutterY={[6]}>
      <Box width={[1, 1 / 2, 1 / 3]}>
        <H2 children={`Similar`} mb={3} color="text.reverse" />
        <Text
          children={`We’ve curated some artists that we think you might also like.`}
          mb={4}
          color="text.reverseBody"
        />
        <Button
          children={`See Full Lineup`}
          variant="reverseAlt"
          as={Link}
          to={"/" + festivalUrl + "/lineup"}
        />
      </Box>
      {relatedArtists.map((artist, index) => (
        <Box
          width={[1, 1 / 2, 1 / 3]}
          key={"concert" + index + (artist ? artist.uid : "")}
        >
          {!artist.image.url && <Box width="100%" pb={"75%"} bg="bg.alt" />}
          {artist.image && artist.image.url && (
            <Link to={"/" + festivalUrl + "/artist/" + artist.uid}>
              <PrismicImage
                image={artist.image}
                alt={artist.artist_name && RichText.asText(artist.artist_name)}
              />
            </Link>
          )}
          {artist.artist_name && (
            <Text mt={2} fontWeight={600} color="text.reverse">
              <Link
                children={RichText.asText(artist.artist_name)}
                to={"/" + festivalUrl + "/artist/" + artist.uid}
              />
            </Text>
          )}
        </Box>
      ))}
    </FlexGrid>
  </SectionWrapper>
);

export default Related;
