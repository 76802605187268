import React from "react";
import { Link } from "gatsby";
import { ellipsis } from "polished";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import { H6, Icon, SectionWrapper } from "src/components";

const NavItem = ({ label, icon, to }) => (
  <Link
    to={to}
    css={`
      display: flex;
      align-items: center;
      max-width: 40%;
      color: ${props => props.theme.colors.text.default};
      &:first-child {
        > div {
          order: -1;
          margin-right: 4px;
        }
      }
      &:last-child {
        > div {
          order: 1;
          margin-left: 4px;
        }
      }
    `}
  >
    <div>
      <Icon
        symbol={icon}
        size={1.5}
        style={{
          verticalAlign: "middle",
          color: "inherit",
          fill: "currentColor"
        }}
      />
    </div>
    <H6 children={label} style={{ ...ellipsis() }} />
  </Link>
);

const ArtistNavigation = ({ festival, nextArtist, previousArtist }) => (
  <SectionWrapper pt={[6, 0]} pb={[6, 8]} bg="bg.default">
    <nav
      css={`
        display: flex;
        justify-content: space-between;
      `}
    >
      {previousArtist && (
        <NavItem
          label={
            `Prev: ` + previousArtist.artist_name &&
            previousArtist.artist_name[0] &&
            previousArtist.artist_name[0].text
          }
          icon={mdiChevronLeft}
          to={"/" + festival + "/artist/" + previousArtist.uid}
        />
      )}
      {nextArtist && (
        <NavItem
          label={
            `Next: ` + nextArtist.artist_name &&
            nextArtist.artist_name[0] &&
            nextArtist.artist_name[0].text
          }
          icon={mdiChevronRight}
          to={"/" + festival + "/artist/" + nextArtist.uid}
        />
      )}
    </nav>
  </SectionWrapper>
);

export default ArtistNavigation;
