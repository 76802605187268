import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import { Box, Button } from "src/components";

function Sidebar({ festivalId, listenOptions }) {
  const [spotifyEmbed, setSpotifyEmbed] = useState(undefined);

  useEffect(() => {
    if (listenOptions) {
      listenOptions.forEach(option => {
        if (option.music_type === "Spotify") {
          if (option.link && option.link.url) {
            setSpotifyEmbed(
              option.link.url
                .split("?")[0]
                .replace("open.spotify.com", "open.spotify.com/embed")
            );
          }
        }
      });
    }
  }, []);

  return (
    <Box>
      <Box mb={5}>
        <Button
          children={`Get tickets`}
          as={Link}
          to={"/" + festivalId + "/tickets"}
          width={1}
          variant="primary"
        />
      </Box>
      <Box display={["none", null, "initial"]}>
        {spotifyEmbed && (
          <iframe
            title="spotify"
            src={spotifyEmbed}
            width="100%"
            height="400"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          />
        )}
      </Box>
    </Box>
  );
}

export default Sidebar;
